import React from "react"
import { StoryGroup } from "components"

export const LinkMap = {
  App: "44685c38839d",
  Utils: "44685c38839d",
  Store: "44685c38839d",
  History: "44685c38839d",
  View: "44685c38839d",
  Container: "44685c38839d",
  Router: "44685c38839d",
  Redux: "44685c38839d",
  Hooks: "44685c38839d",
  UseEffect: "44685c38839d",
  UseState: "44685c38839d",
  PropsManagement: "66c196962d14",
}

const stories = [
  {
    title: "RA-01 Project Structure",
    postId: "44685c38839d",
  },
  {
    title: "RA-01 Project Structure Remastered",
    postId: "7649a1d6b04d",
  },
  {
    title: "RA-01 Project Naming Convention",
    postId: "94702299f252",
  },
  {
    title: "RA-02 Page Structure",
    postId: "a5b786022391",
  },
  {
    title: "RA-03 Comp Re-Render",
    postId: "eb660af70b5",
  },
  {
    title: "RA-04 Icon Management",
    postId: "e7154deb9323",
  },
  {
    title: "RA-05 Figma-Arch Sync",
    postId: "8b2a2fd87045",
  },
  {
    title: "RA-06 Server State Sync",
    postId: "2d0290a9240e",
  },
  {
    title: "RA-07 Design System",
    postId: "7e62e1da3438",
  },
  {
    title: "RA-07 Design System Impl",
    postId: "9b4ff7350d2d",
  },
  {
    title: "RA-08 Own Style & Naming",
    postId: "1dc282e27002",
  },
  {
    title: "RA-09 CSS Scope",
    postId: "e07a2189aafc",
  },
  {
    title: "RA-10 Comp Naming",
    postId: "ab0eb4b9914d",
  },
  {
    title: "RA-11 Color Palette",
    postId: "5b9137c4b5a3",
  },
  {
    title: "RA-12 Text",
    postId: "91dc2de6fb24",
  },
  {
    title: "RA-13 Asset Management",
    postId: "b17c797b3e56",
  },
  {
    title: "RA-14 Props Management",
    postId: "66c196962d14",
  },
  {
    title: "RA-15 React Page ve Platform Components",
    postId: "67ee16467d28",
  },
  {
    title: "RA-16 Pros of Seperation of Platform Components",
    postId: "56c1f5a42244",
  },
  {
    title: "RA-17 How To Test Platform Components",
    postId: "a19a04cb1336",
  },
  {
    title: "RA-18 Form Validation",
    postId: "7bc2dd224693",
  },
  {
    title: "RA-19 Authorization I",
    postId: "33aca65c0c36",
  },
  {
    title: "RA-20 Authorization II",
    postId: "d5841d77fbc1",
  },
  {
    title: "RA-21 Authorization III",
    postId: "b85b66a3eb56",
  },
  {
    title: "RA-22 Layouts",
    postId: "d045dcedd9f6",
  },
  {
    title: "RA-23 Layout Styling",
    postId: "c4faa40275c3",
  },
  {
    title: "RA-24 Theme",
    postId: "864c02a28a27",
  },
  {
    title: "RA-25 Localization",
    postId: "73717ab0f785",
  },
  {
    title: "RA-25 Localization",
    postId: "73717ab0f785",
  },
  {
    title: "RA-26 JSS Problems",
    postId: "5fa36c41c032",
  },
]

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "React Architecture",
  path: "react-architecture",
}

const ReactArchitecturePage = () => {
  return (
    <StoryGroup
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
    />
  )
}

export default ReactArchitecturePage
